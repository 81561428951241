export function triggerCustomEvent(eventTarget, eventName, eventData) {
	let event;

	if (!eventTarget || !eventName) return false;
	if (typeof eventData === 'undefined') eventData = {};

	if (window.CustomEvent) {
		event = new CustomEvent(eventName, {detail: eventData});
	} else {
		event = document.createEvent('CustomEvent');
		event.initCustomEvent(eventName, true, true, eventData);
	}

	return eventTarget.dispatchEvent(event);
}