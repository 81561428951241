function pascalToKebab(str) {
	return str.replace(/([A-Z])/g, function($1){return '-'+$1.toLowerCase();}).replace(/^-/, '');
}

function camelToKebab(str) {
	return str.replace(/([A-Z])/g, function($1){return '-'+$1.toLowerCase();});
}

function convertToQueryParams(obj) {
	return Object.keys(obj).reduce((t, v) => {
		var query = encodeURIComponent(v) + '=' + encodeURIComponent(obj[v]);

		if(t) {
			query = '&' + query;
		}

		return (t + query);
	}, '');
}

function parseQueryParams(q) {
	let query = q || location.search;

	if (query) {
		if (query.indexOf('?') === 0) {
			query = query.substr(1);
		}

		return query.split('&').reduce((t, v) => {
			let arr = v.split('=');
			let key = decodeURIComponent(arr[0]);
			let value = decodeURIComponent(arr[1]);

			t[key] = value;
			return t;
		}, {});
	}

	return false;
}

function parseParamsFromData(element) {
	const dataset = element.dataset || {};
	return Object.keys(dataset).reduce((obj, data) => {
		if (data.indexOf('param') === 0 || data.indexOf('param') === 0) {
			let k = pascalToKebab(data.slice(5));
			obj[k] = dataset[data];
		}

		return obj;
	}, {});
}

export function Ajax(callback) {
	return function(event) {

		if (!event.defaultPrevented) event.preventDefault();

		const method = this.getAttribute('method') || 'POST';
		let action = this.getAttribute('action') || location.pathname;
		let params = new FormData(this);
		
		// if (method === 'GET') {
		// 	action += `?${params}`;
		// 	params = null;
		// }

		const req = new XMLHttpRequest();
		req.open(method, action, true);

		//if (method !== 'GET') req.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

		req.onload = () => {
			if (req.status >= 200 && req.status < 400) {
				
				const content = JSON.parse(req.responseText);
				if(callback) return callback(false, content);
			} else {
				if(callback) return callback(true, 'We reached our target server, but it returned an error');
			}
		}

		req.onerror = () => {
			if(callback) return callback(true, 'There was a connection error of some sort');
		}

		req.send(params);
	}

}