const throttle = require('lodash.throttle');

// http://stackoverflow.com/a/23749355
function getAbsoluteHeight(el) {
  // Get the DOM Node if you pass in a string
  el = (typeof el === 'string') ? document.querySelector(el) : el; 

  var styles = window.getComputedStyle(el);
  var margin = parseFloat(styles['marginTop']) +
               parseFloat(styles['marginBottom']);

  return Math.ceil(el.offsetHeight + margin);
}

// https://plainjs.com/javascript/traversing/get-siblings-of-an-element-40/
function getNextSiblings(el, filter) {
    let siblings = [];
    while (el = el.nextSibling) { if (!filter || filter(el)) siblings.push(el); }
    return siblings;
}

// Function from David Walsh: http://davidwalsh.name/css-animation-callback
function whichTransitionEvent() {
  var t,
      el = document.createElement('fakeelement');

  var transitions = {
    'transition'      : 'transitionend',
    'OTransition'     : 'oTransitionEnd',
    'MozTransition'   : 'transitionend',
    'WebkitTransition': 'webkitTransitionEnd'
  }

  for (t in transitions){
    if (el.style[t] !== undefined){
      return transitions[t];
    }
  }
}

function onTransitionEnd(event) {
	event.target.parentNode.removeChild(event.target);
}

function fadeNotification(notification, top) {
	const transitionEvent = whichTransitionEvent();

	return function() {
		let h = +getAbsoluteHeight(notification);

		let nextSiblings = getNextSiblings(notification, function(el) {
			return el.classList.contains('Notification');
		});

		notification.addEventListener(transitionEvent, onTransitionEnd);
		notification.style.opacity = 0;

		nextSiblings.forEach((el) => {
			let styles = window.getComputedStyle(el);
			let currentTop = parseFloat(styles['top']);
			el.style.top = (currentTop - h - top) + 'px';
		});
	}
}

function snapScrollingNotifications(reference) {

	function snapScrolling() {
		const refTop = Math.max(reference.getBoundingClientRect().top, 0);

		Array.from(document.querySelectorAll('.notification')).forEach((notification) => {
			notification.style.marginTop = `${refTop}px`;
		});
	}

	snapScrolling();

	window.addEventListener('scroll', throttle(snapScrolling.bind(this), 50));


	document.body.dataset.notified = true;
}

export function Notification(data) {
	let { type, message, container, delay} = data;
	const top = 10;
	const paddingTop = 0;
	const duration = 3000;

	if (!container) container = document.querySelector('.Main') || document.body;
	if (!delay)  delay = duration;

	let top2 = paddingTop + top;
	let alerts = document.querySelectorAll('.notification');

	if (alerts.length) {
		top2 = paddingTop + Array.from(alerts).reduce((carry, item) => {
			return carry + +getAbsoluteHeight(item) + top;
		}, top);
		delay += (duration * alerts.length);
	}

	let notification = document.createElement('div');

	notification.style.top = `${top2}px`;
	notification.classList.add('notification');
	if (type) notification.classList.add(`notification--${type}`);
	notification.textContent = message || '';
	notification.setAttribute('role', 'alert');

	notification = container.appendChild(notification);
	notification.style.display = 'block';
	notification.style.opacity = 1;

	let timer = window.setTimeout(fadeNotification(notification, top), delay);
	notification.addEventListener('click', function() {
		fadeNotification(notification, top).call();
		window.clearTimeout(timer);
	});

	if (!document.body.dataset.notified) {
		snapScrollingNotifications(container);
	}

	return notification;
}