import { Ajax } from './ajax';
import { Notification } from './notifications';
import { triggerCustomEvent } from './events';

export const Forms = {
	init: function() {
		Array.from(document.querySelectorAll('form:not([data-disable-ajax])')).forEach(this.form);

		// Array.from(document.querySelectorAll('[data-toggle-password]')).forEach((button) => {

		// 	let inputs = button.parentNode.querySelectorAll('input[type="text"], input[type="password"]');

		// 	button.addEventListener('click', (e) => {
		// 		e.preventDefault();
		// 		let inputs = button.parentNode.querySelectorAll('input[type="text"], input[type="password"]');

		// 		inputs.forEach((input) => {
		// 			let newType = (input.type === 'text' ? 'password' : 'text');
		// 			let newInput = document.createElement('input');
		// 			newInput.type = newType;
		// 			if(input.size) newInput.size = input.size;
		// 		    if(input.value) newInput.value = input.value;
		// 		    if(input.name) newInput.name = input.name;
		// 		    if(input.id) newInput.id = input.id;
		// 		    if(input.className) newInput.className = input.className;

		// 		    input.parentNode.replaceChild(newInput,input);
  //   				return newInput;
		// 			// input.type = (input.type === 'text' ? 'password' : 'text');
		// 		});
		// 	});
		// });

		// Array.from(document.querySelectorAll('[data-toggle-fieldset]')).forEach((input) => {

		// 	this.toggleFieldset(input);
			
		// 	input.addEventListener('change', () => {
		// 		this.toggleFieldset(input);
		// 	}, true);
		// });

		// Array.from(document.querySelectorAll('[data-toggle-fieldset-off]')).forEach((input) => {

		// 	this.toggleFieldset(input, true);

		// 	input.addEventListener('change', () => {
		// 		this.toggleFieldset(input, true);
		// 	}, true);
		// });

		Array.from(document.querySelectorAll('form[data-disable-ajax]')).forEach(this.lockOnSubmit);
	},

	// toggleFieldset(input, reverse) {
	// 	let form = input.closest('form');
	// 	let fieldsets = !reverse ? input.dataset.toggleFieldset : input.dataset.toggleFieldsetOff;

	// 	function disableFieldset(fieldset) {
	// 		fieldset.disabled = true;
	// 		fieldset.classList.add('is--disabled');
	// 		fieldset.setAttribute('aria-disabled', true);
	// 	}

	// 	function enableFieldset(fieldset) {
	// 		fieldset.disabled = false;
	// 		fieldset.classList.remove('is--disabled');
	// 		fieldset.setAttribute('aria-disabled', false);
	// 	}

	// 	function enableOrDisableFieldset(fieldset) {
	// 		if(input.checked) {
	// 			if (!!reverse) {
	// 				disableFieldset(fieldset);
	// 			} else {
	// 				enableFieldset(fieldset);
	// 			}
	// 		} else {
	// 			if (!!reverse) {
	// 				enableFieldset(fieldset);
	// 			} else {
	// 				disableFieldset(fieldset);
	// 			}
	// 		}
	// 	}

	// 	if (fieldsets && fieldsets.length) {
	// 		fieldsets = fieldsets.split(',');

	// 		fieldsets.forEach((fieldset) => {
	// 			fieldset = fieldset.trim();
	// 			if (fieldset in form) {

	// 				fieldset = form[fieldset];
	// 				enableOrDisableFieldset(fieldset);
	// 			} else {
	// 				let query = Array.from(form.querySelectorAll(fieldset));

	// 				if(query.length) {
	// 					query.forEach((el) => {
	// 						enableOrDisableFieldset(el);
	// 					});
	// 				}
	// 			}
	// 		});
	// 	}
	// },
	lockOnSubmit: function(form) {
		form.addEventListener('submit', (e) => {
			if (form.dataset.submitting) return false;
			
			form.dataset.submitting = true;
			Array.from(form.querySelectorAll('[type="submit"]')).forEach(function(input) {
				input.disabled = true;
			});
		});
	},
	form: function(form) {
		const callback = Forms.callback.bind(form);

		form.addEventListener('submit', (e) => {
			e.preventDefault();

			if (form.dataset.submitting) return false;

			form.dataset.submitting = true;
			Array.from(form.querySelectorAll('[type="submit"]')).forEach(function(input) {
				input.disabled = true;
			});

			return Ajax(callback).call(form, e);
		});

		form.addEventListener('focus', function(Event) {
			let input = Event.target;
			input.classList.remove('is--invalid');

			let messagebox = input.parentNode.querySelector(`[data-message-for="${input.getAttribute('name')}"]`);

			if (messagebox) {
				messagebox.setAttribute('aria-hidden', true);
				messagebox.textContent = '';
			}
		}, true);

		form.addEventListener('submit:success', function(Event) {
			Array.from(form.querySelectorAll('input:not([type=submit]), textarea')).forEach(function(input) {
				input.value = '';
			});;

			Array.from(form.querySelectorAll('[type="submit"]')).forEach(function(input) {
				input.removeAttribute('disabled');
			});
		});

		form.addEventListener('submit:error', function(Event) {

		});
	},
	callback: function(connectionError, content) {

		const form = this;
		delete form.dataset.submitting;

		if (connectionError) {
			return Notification({
				type: 'error',
				message: connectionError
			});
		}
		
		if (content.status === 'error') {
			if (content.errors && content.errors.length) {
				content.errors.forEach((err) => {
					let input = this.querySelector(`[name="${err.name}"]`);
					if (input) input.classList.add('is--invalid');

					let messagebox = input.parentNode.querySelector(`[data-message-for="${err.name}"]`);

					if (messagebox) {
						messagebox.setAttribute('aria-hidden', false);
						messagebox.textContent = err.message;
					}
				});
			}

			triggerCustomEvent(form, 'submit:error', content);

			return Notification({
				type: content.notification.status,
				message: content.notification.text
			});
		}

		triggerCustomEvent(form, 'submit:success', content);
		return Notification({
			type: content.notification.status,
			message: content.notification.text
		});
	}
}